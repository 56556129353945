import React, { FC } from "react"
import { CalculationSection as Section } from "./calculationSection"
import { CalculationPaperSize as PaperSize } from "./calculationPaperSize"
import { CalculationPaperType as PaperType } from "./calculationPaperType"
import { CalculationPaperPrint as PaperPrint } from "./calculationPaperPrint"
import { CalculationPaperFolding as PaperFolding } from "./calculationPaperFolding"
import { CalculationQuantity as Quantity } from "./calculationQuantity"
import { CalculationUpload as Upload } from "./calculationUpload"
import { CalculationPrice as Price } from "./calculationPrice"
import { CalculationContact } from "./calculationContact"
import { CalculationSentThanks } from "./calculationSentThanks"

type ThankYouState = {
  showThanks: boolean
  demandId: string
}

export const LeafletsCalculation: FC = () => {
  const [thanksState, setThanksState] = React.useState<ThankYouState>({ showThanks: false, demandId: '' })
  const contactsRef = React.useRef<HTMLDivElement>(null)
  const quantityRef = React.useRef<HTMLDivElement>(null)

  const handleSent = (demandId: string) => {
    setThanksState({ showThanks: true, demandId })
    window.scrollTo(0, 0)
  }

  const handleScrollTo = (section: "customQuantity" | "contacts") => {
    const element = section === "customQuantity" ? quantityRef : contactsRef
    if (element.current) {
      element.current.scrollIntoView({ behavior: "smooth", })
    }
  }

  return (
    <div className="container page calculation">
      <h1 className="main-title page">
        <span>Letáky kalkulace</span>
      </h1>
      {thanksState.showThanks ? (
        <CalculationSentThanks demandId={thanksState.demandId} />
      ) : (
        <>
          <div>Nejoblíbenější letáky na míru.</div>
          <div>
            <Section title="Rozměry v rozloženém stavu">
              <PaperSize />
            </Section>
            <Section title="Typ papíru">
              <PaperType />
            </Section>
            <Section title="Potisk">
              <PaperPrint />
            </Section>
            <Section title="Způsob složení">
              <PaperFolding />
            </Section>
            <Section title="Množství">
              <Quantity ref={quantityRef} />
            </Section>
            <Section title="Motivy, soubory">
              <Upload />
            </Section>
            <Section title="Kontakt">
              <CalculationContact ref={contactsRef} />
            </Section>
            <Price onSent={handleSent} onScrollTo={handleScrollTo} />
          </div>
        </>
      )}
    </div>
  )
}
